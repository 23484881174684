<splus-forms id="DepartamentosPage" [submit]="submitted" [messageProcess]="messageProcess"
    [showMessageProcess]="showMessageProcess" [FormRecord]="FormRecord">

    <form [formGroup]="FormRecord" (ngSubmit)="onSubmit($event)" autocomplete="off"
        [ngClass]="{'formGroup-read': processType == ProcessType.view}">

        <splus-forms-header [isLoading]="isLoading">

            <splus-forms-main-title [title]="title" [icon]="icon"> </splus-forms-main-title>


            <splus-forms-toolbar [options]="options">
                <splus-forms-toolbar-buttons [moreOptions]="moreOptions" [processType]="processType">

                    <button type="Submit" [disabled]="isLoading"
                        *ngIf="processType == ProcessType.create || processType == ProcessType.edit" kendoButton
                        rounded="small" themeColor="primary">
                        <i class="fa-solid fa-floppy-disk"></i> Guardar
                    </button>

                    <button [disabled]="isLoading" type="button" *ngIf="processType == ProcessType.view" kendoButton
                        rounded="small" themeColor="primary" (click)="goEdit()">
                        <i class="fa-solid fa-pencil"></i> Editar
                    </button>

                    <button *ngIf="processType == ProcessType.edit" type="button" kendoButton rounded="small"
                        themeColor="base" (click)="cancelEdit()">
                        Cancelar
                    </button>

                    <button *ngIf="processType == ProcessType.view" type="button" kendoButton rounded="small"
                        themeColor="base" (click)="goback()">
                        Volver
                    </button>

                    <button *ngIf="processType == ProcessType.create" type="button" kendoButton rounded="small"
                        themeColor="base" (click)="clearForm()">
                        Limpiar
                    </button>

                </splus-forms-toolbar-buttons>
            </splus-forms-toolbar>


        </splus-forms-header>

        <splus-forms-fields [processType]="processType">

            <splus-forms-group title="Información Básica" #separadorInformacionBasica mainLayout="layout-simple">

                <div class="max-w-[280px]">
                    <splus-field *ngVar="f.nombre as ctrl" [splusFormControl]="ctrl" >
                        <kendo-textbox splusInput [splusGroup]="separadorInformacionBasica" type="text"
                            [id]="ctrl.nameControl" (blur)="BuscarRegistroPornombre()" [formControl]="ctrl">

                            <ng-template kendoTextBoxPrefixTemplate>
                                <div class="m-1">
                                    <i [ngClass]="icon" class="text-primary"></i>
                                </div>
                                <kendo-textbox-separator></kendo-textbox-separator>
                            </ng-template>

                            <ng-template kendoTextBoxSuffixTemplate>
                                <div *ngIf="searchingnombre" class="k-i-loading w-5">&nbsp;</div>
                            </ng-template>



                        </kendo-textbox>
                    </splus-field>
                </div>
                <div class="max-w-[380px]">
                    <splus-field *ngVar="f.descripcion as ctrl" [splusFormControl]="ctrl">
                        <kendo-textarea splusInput [splusGroup]="separadorInformacionBasica" type="text"
                            [id]="ctrl.nameControl" [formControl]="ctrl">
                        </kendo-textarea>
                    </splus-field>
                </div>




            </splus-forms-group>


<!--
            <splus-tabs [keepTabContent]="true">
                <splus-tab [selected]="true" *ngIf="processType != ProcessType.create && Data.id">

                    <ng-template splusTabTitle>
                        <span class="select-none"> <i class=" text-nomina fa-duotone fa-solid fa-memo-pad mr-1"></i>
                            Sub Departamentos</span>
                    </ng-template>

                    <ng-template splusTabContent>
                        <div>
                            <tab-subdepartamento-empleados [processType]="processType"> </tab-subdepartamento-empleados>
                        </div>
                    </ng-template>
                </splus-tab>

            </splus-tabs>
-->

        </splus-forms-fields>

    </form>
</splus-forms>