import { Component, OnInit, OnDestroy, inject } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SplusBaseForm } from '@src/app/models/base/BaseFormComponent';
import { HttpErrorResponse } from "@angular/common/http";

import {
  ProcessType,
  SPlusDialogCloseResult,
  SPlusDialogSettings, 
  SPlusMoreOption,
  SPlusOption,
} from "@saludplus/forms";

import { Departamentos } from "@src/app/models/Nomina/Departamentos.model";
import { DepartamentosService } from "../services/departamentos.service";
import { DepartamentosSettings } from "../config/departamentos.settings"; 

@Component({
  selector: "app-departamentos",
  templateUrl: "./departamentos.component.html",
  styleUrls: ["./departamentos.component.scss"],
})
export class DepartamentosComponent extends SplusBaseForm implements OnInit, OnDestroy {

  // Forms   
  Data = new Departamentos();
 
  //listado de opciones --
  options: SPlusOption[] = [
    {
      id: "Listado",
      icon: "fa-solid fa-magnifying-glass",
      text: "Listado",
      link: "/nomina/departamentos/listado",
    },
    {
      id: "Ayuda",
      icon: "fa-solid fa-circle-question",
      text: "Ayuda",
      action: () => {
        this.OpenDocumentacion();
      },
    },
  ];

  //listado de más opciones --
  moreOptions: SPlusMoreOption[] = [
    {
      id: "CrearDepartamentos",
      showIn: ProcessType.view,
      text: "Crear nuevo departamentos",
      icon: "fa-duotone fa-plus",
      action: () => this.newRecord(),
    },
    {
      id: "EliminarDepartamentos",
      showIn: ProcessType.edit,
      text: "Eliminar",
      icon: "fa-solid fa-trash-can",
      action: () => this.dialogEliminar(),
    },
  ];

  private unsubcribe$ = new Subject<void>();

 
  private service = inject(DepartamentosService);
  private settings = inject(DepartamentosSettings);

  numeroDocumento = "";  
  
  
  constructor() {
    super()
  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.title = this.route.snapshot.data['title'];
    this.icon = this.route.snapshot.data['icon'];
    this.titleService.setTitle(this.title);

    this.FormRecord = this.settings.FormComponents();


    this.FormRecord.disable();
 
    this.After(); 
  }

  
  /**
   * Abreviacion para acceder a los controles del FormRecord
   */
  public get f() : any{
    return this.FormRecord.controls;
  }

  ngOnDestroy() {
    this.unsubcribe$.next();
    this.unsubcribe$.complete();
  }

  /**
   * Guardar o actualizar el registro
   */
  async onSubmit(event) {
    event.preventDefault();
    this.submitted = true;
    if (this.FormRecord.valid) {
      if (this.FormRecord.pristine || this.searchingnombre) return;
      this.isLoading = true;
      if (this.processType === this.ProcessType.edit) {
        this.UpdateRecord();
      } else {
        this.SaveRecord();
      }
    } else {
      this.isLoading = false;
    }
  }

  /**
   * Guardar
   */
  SaveRecord() {
    this.service
      .Guardar<number, Departamentos>(this.FormRecord.value)
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          if (res) {
            this.router.navigate([`${res}`], {
              queryParams: { mode: "i" },
              relativeTo: this.route,
            });
          }

        }
      });
  }

  /**
   * Actualizar
   */
  UpdateRecord() {
    this.isLoading = true;
    this.submitted = true;
    if (this.FormRecord.valid && !this.FormRecord.pristine) {
      let data = this.FormRecord.value as Departamentos;
      data.id = this.id;
      this.service
        .Actualizar<boolean, Departamentos>(data)
        .subscribe({
          next: (res) => {
            this.isLoading = false;
            if (res) {
              this.router.navigate([], {
                queryParams: { mode: "u" },
                queryParamsHandling: "merge",
              });
            }


          }
        });
    } else {
      this.isLoading = false;
    }
  }
  /**
   * Eliminar
   */
  DeleteRecord() {
    this.isDeleting = true;
    this.isLoading = true;
    const userSearchParams = {
      id : this.id
    };
    this.service.Eliminar<boolean>(userSearchParams).subscribe({
      next: (res) => {
        if (res) {
          this.isDeleting = false;
          this.router.navigate(["../"], {
            queryParams: { mode: "d" },
            queryParamsHandling: "merge",
            relativeTo: this.route,
          });
        }

      }
    });
  }

  /**
   * Abre alerta para eliminar registro
   */
  dialogEliminar() {
    let dialogSettings = {} as SPlusDialogSettings;
    dialogSettings.title = "Eliminar departamento";
    dialogSettings.description =
      "¿Estas seguro que desea eliminar este departamento?";
    dialogSettings.actions = [
      { text: "Cancelar" },
      { text: "Si, Eliminar", themeColor: "warning", value: true },
    ];
    this.splusFormsDialogsService
      .Show(dialogSettings)
      .pipe(takeUntil(this.unsubcribe$))
      .subscribe((result) => {
        if (result instanceof SPlusDialogCloseResult) {
          console.log("close", result);
        } else {
          if (result.value) {
            this.DeleteRecord();
          }
        }
      });
  }

  /**
   * Limpiar formulario
   */
  clearForm() {
    this.FormRecord.reset();
    this.FormRecord.updateValueAndValidity();
    // Valores por defecto
    this.SetDefaultValues();
    this.submitted = false;
  }
  /**
   * Setea valores por defecto
   */
  SetDefaultValues() {
  }

  /**
   * formulario en modo edicción
   */
  goEdit() {
    this.routingState.goEdit();
  }

  /**
   * Cancelar insercion o edición
   */
  cancelEdit() {
    if (this.processType === ProcessType.create) {
      this.goback();
    } else if (this.processType === ProcessType.edit) {
      // validar si se modificó el formulario visualmente
      if (!this.FormRecord.pristine) {
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Cancelar edición";
        dialogSettings.isHtml = true;
        dialogSettings.description =
          "Hay cambios pendientes por guardar. <br/>¿Estás seguro que desea cancelar la edición?";
        dialogSettings.actions = [
          { text: "Seguir Editando" },
          { text: "Si, Cancelar", themeColor: "primary", value: true },
        ];
        this.splusFormsDialogsService
          .Show(dialogSettings)
          .pipe(takeUntil(this.unsubcribe$))
          .subscribe((result) => {
            if (result instanceof SPlusDialogCloseResult) {
              console.log("close", result);
            } else {
              if (result.value) {
                this.cancelUpdate();
              }
            }
          });
      } else {
        this.goback();
      }
    }
  }



  /**
   * Cargar el registro por su id
   */
  LoadRecord() {

    this.numeroDocumento = "";
    this.isLoading = true;
    const userSearchParams = {
      id : this.id
    };
    this.service.Buscar<Departamentos>(userSearchParams).subscribe({
      next: (res) => {
        this.isDeleting = false;
        if (res) {
 
          this.Data = res;
          this.FormRecord.patchValue(this.Data);
        }
      },
      error: (err) => {
        if (err instanceof HttpErrorResponse && err.status == 401) return;
        console.warn("Error critico de lado del cliente", err);
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Cargar departamento";
        dialogSettings.description =
          "Hubo un error al intentar cargar el departamento por favor intentelo más tarde";
        dialogSettings.actions = [{ text: "Cerrar" }];
        this.splusFormsDialogsService.Show(dialogSettings);
      },
    });
  }

  

  After() {
    this.FormRecord.enable();

    if (this.id) {
      this.LoadRecord();
    } else {
      this.SetDefaultValues();
    }
    this.isLoading = false;
  }



  
searchingnombre = false;
nombre;
/**
 * Buscar registro por nombre
 */
 BuscarRegistroPornombre(){

  //validación de campos
  if (!this.f.nombre.value) { return; }

  const nombre = this.f.nombre.value;

  this.searchingnombre = true;

  this.Data = new Departamentos();
  const searchParams = {
    nombre: this.f.nombre.value,
    
  };

  this.service.ValidarExistencia<Departamentos>(searchParams).subscribe({
    next: (res) => {
      this.searchingnombre = false;
      if (res) {
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Departamento Encontrado";
        dialogSettings.description = `El Nombre ${this.f.nombre.value} ya existe en el sistema.`;
        dialogSettings.actions = [
          { text: "Cancelar" },
          { text: "Ver departamento", themeColor: "primary", value: true },
        ];
        this.splusFormsDialogsService
          .Show(dialogSettings)
          .pipe(takeUntil(this.unsubcribe$))
          .subscribe((result) => {
            if (result instanceof SPlusDialogCloseResult || !result.value) {
              this.searchingnombre = false;
              this.isLoading = false;
              if (this.route.routeConfig.path !== ":id") {
                this.f.nombre.setValue("");
              } else {
                this.f.nombre.setValue(this.nombre);
              }
            } else {
              if (result.value) {
                if (this.route.routeConfig.path === ":id") {
                  this.processType = this.ProcessType.view;
                  this.id = res.id;
                  this.LoadRecord();
                  this.router.navigate(["nomina/departamentos/" + this.id,]);
                  this.searchingnombre = false;
                } else {
                  this.processType = this.ProcessType.view;
                  this.searchingnombre = false;
                  this.router.navigate([res.id], {
                    relativeTo: this.route,
                  });
                }
              }
            }
          });
      }
    },
    error: (err) => {
      this.handleError(err, "BuscarRegistroPornombre()")

      this.isLoading = false; 
    },
  });

 }


 
 
 

  /**
   * Cancelar edicion
   */
  cancelUpdate() {
    this.FormRecord.patchValue(this.Data);
    this.FormRecord.markAsPristine();
    this.router.navigate([], {
      queryParams: { mode: null },
      queryParamsHandling: "merge",
    });
  }


}