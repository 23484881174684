import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "@app/services/Security/guard.service"; 
import { SearchInListComponent } from "@src/app/core/Search/search-in-list/search-in-list.component"; 
import { DepartamentosSettings } from "./config/departamentos.settings";
import { DepartamentosComponent } from "./components/departamentos.component";
import { DepartamentosSearch } from "./config/departamentos.search";

const routes: Routes = [
  {
    path: "listado",
    component: SearchInListComponent,
    data: { title: "Departamentos Listado" },
  },
  { path: "", component: DepartamentosComponent },
  { path: ":id", component: DepartamentosComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DepartamentosRoutingModule {
 
  //Routing principal
  public static RoutingDepartamentos = {
    path: "nomina/departamentos",
    loadChildren: () =>
      import("./departamentos.module").then(
        (m) => m.DepartamentosModule
      ),
    canActivate: [AuthGuard],
    data: {
      title: `Departamentos`,
      icon: "fa-sharp fa-light fa-list-timeline",
      setting: DepartamentosSettings,
      search : DepartamentosSearch
    },
  };
}
